"use client";
import Lottie from "lottie-react";
import animationData from "@/utils/animationJson/new_error_404.json";
import { Link } from "@/navigation";
import { useTranslations } from "next-intl";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Image from "next/image";

const Error = () => {
  const errorText = useTranslations("Home");
  const mobile = useClientMediaQuery("(max-width: 768px)");

  return (
    <div className="position-relative not-found-main">
      <div
        className="d-flex justify-content-center position-relative flex-column mb-lg-5 mb-md-5 mb-0 mt-lg-0 mt-md-0 mt-5"
      >
        <Image
          unoptimized
          src={"https://e-procure-alpha-headless-cms.s3.me-south-1.amazonaws.com/internal_04d16d28b5.webp"}
          height={150}
          width={280}
          alt="product"
          className="position-absolute for-zero-image"
        />
        <Lottie animationData={animationData} />
        <div
          className="d-flex justify-content-center"
        >
          <div className="text-center">
            <h5 className="mb-3">
           500 {errorText("internal_server")}
            </h5>
            <p className="text-gray mb-2">
            {errorText("internal_desc")}
            </p>
            <Link
              href={"/"}
              className="header-sign-up-btn rounded-5 px-4 py-2 mt-md-0"
            >
              {errorText("try_again")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
